<template>
  <message
    :tab="tab"
    :message.sync="computedMessage"
    :load-message="loadMessage"
    :save="saveMessage"
    v-on="$listeners"
    is-outbox
  />
</template>

<script>

export default {
  components: {
    Message: () => import('@/pages/messages/message')
  },
  data () {
    return {
      dataMessage: {}
    }
  },
  computed: {
    computedMessage: {
      get () {
        return this.dataMessage
      },
      set (v) {
        this.dataMessage = v
      }
    }
  },
  methods: {
    async loadMessage () {
      this.$emit('document-reset', this.tab)
      const result = await this.$http().get('/core/messages/outbox/' + this.tab.documentId)
      const m = result.data
      m.sender.fullname = m.sender.fullName
      m.sender.email = m.sender.eMail
      m.recipients.forEach(r => {
        r.fullname = r.fullName
        r.email = r.eMail
      })

      this.computedMessage = m
    },
    async saveMessage () {
      this.dataMessage.message.createAt = null
      this.dataMessage.message.updateAt = null
      this.dataMessage.message.createBy = null
      this.dataMessage.message.updateBy = null

      await this.$http().post('/message', this.computedMessage)
    }
  },
  props: {
    tab: Object
  }
}
</script>
